<template>
  <div>
    <div class="banner">
      <div class="container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{$t("header.about")}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t("header.aboutUs")}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="chart-wrapper flex flex__justify-space-between">
          <div class="chart-text">
            <h4>{{$t("aboutUs.ourAdvantage.service1")}}</h4>
            <p>{{$t("aboutUs.ourAdvantage.describe1")}}</p>chart-text
            <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t("aboutUs.ourAdvantage.trading")}}</button>
          </div>
          <div class="chart-content">
            <line-chart></line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="activity">
      <div class="container flex flex__justify-space-between">
        <div class="activity-pic"></div>
        <div class="activity-detail">
          <h4>{{$t("aboutUs.ourAdvantage.service2")}}</h4>
          <p>{{$t("aboutUs.ourAdvantage.describe2")}}</p>
          <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t("aboutUs.ourAdvantage.trading")}}</button>
        </div>
      </div>
    </div>

    <div class="training">
      <div class="container">
        <h4>{{$t("aboutUs.ourAdvantage.service3")}}</h4>
        <p>{{$t("aboutUs.ourAdvantage.describe3")}}</p>
      </div>
    </div>

    <div class="community">
      <h4>{{$t("aboutUs.ourAdvantage.service4")}}</h4>
      <p>{{$t("aboutUs.ourAdvantage.describe4")}}</p>
      <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t("aboutUs.ourAdvantage.trading")}}</button>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/home/gpb-usd/gpb-chart';

export default {
  name: 'our-advantage',

  components: {
    LineChart
  }
}
</script>

<style scoped lang="scss">
.chart-wrapper.flex {
  margin-top: 72px;
}
.chart-text {
  width: 225px;
  h4 {
    min-height: 100px;
    font-size: 36px;
    color: #0D73D6;
    line-height: 50px;
    margin-top: 24px;
  }
  p {
    margin: 24px 0 42px 0;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 28px;
  }
}
.chart-content {
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(33,66,171,0.1500);
}
.activity {
  margin-top: 100px;
  background: #F5F6F8;
  padding: 79px 0 65px;
  &-pic {
    width: 359px;
    height: 356px;
    background-size: 359px 356px;
    @include bg-image('../../assets/image/about-us/advantage-chart');
  }
  &-detail {
    width: 660px;
    h4 {
      height: 50px;
      font-size: 36px;
      color: #000000;
      line-height: 50px;
      margin-top: 38px;
    }
    p {
      height: 166px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
      margin-top: 24px;
    }
    button {
      margin-top: 24px;
    }
  }
}
.training {
  height: 480px;
  background-size: cover;
  display: flow-root;
  @include bg-image('../../assets/image/about-us/advantage-training');
  h4 {
    height: 50px;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 50px;
    margin-top: 140px;
  }
  p {
    width: 600px;
    height: 84px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    margin-top: 24px;
  }
}
.community {
  height: 460px;
  text-align: center;
  @include bg-image('../../assets/image/about-us/advantage-community');
  padding-top: 100px;
  h4 {
    height: 50px;
    font-size: 36px;
    color: #000000;
    line-height: 50px;

  }
  p {
    margin: 24px auto 66px;
    width: 656px;
    height: 84px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
  }
}
</style>