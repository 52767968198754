var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"service"},[_c('div',{staticClass:"service-describe"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.title'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.service'))}}),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$open('signUp', _vm.$i18n.locale)}}},[_vm._v(_vm._s(_vm.$t("community.multiAccountManagement.transaction")))])]),_c('ul',[_vm._m(0),_c('li',[(_vm.$i18n.locale == 'en')?_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-en-3.png")}}):_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-3.png")}})]),_c('li',[(_vm.$i18n.locale == 'en')?_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-en-4.png")}}):_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-4.png")}})]),_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-box"},[_c('h4',[_vm._v(_vm._s(_vm.$t('community.multiAccountManagement.describe')))]),_vm._m(3),_c('ul',[_c('li',[_vm._m(4),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab1'))}})]),_c('li',[_vm._m(5),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab2'))}})]),_c('li',[_vm._m(6),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab3'))}})]),_c('li',[_vm._m(7),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab4'))}})]),_c('li',[_vm._m(8),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab5'))}})]),_c('li',[_vm._m(9),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('community.multiAccountManagement.tab6'))}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span'),_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-1.png")}}),_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-6.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-describe"},[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-7.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-9.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-10.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-11.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-12.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/Multi-account-13.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/image/community/following-up-tab-5.png")}})])
}]

export { render, staticRenderFns }