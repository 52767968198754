<template>
    <div class="box">
        <div class="service">
            <div class="service-describe">
                <h4 v-html="$t('community.multiAccountManagement.title')"></h4>
                <p v-html="$t('community.multiAccountManagement.service')"></p>
                <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t("community.multiAccountManagement.transaction")}}</button>
            </div>
            <ul>
                <li>
                    <span></span>
                    <img src="@/assets/image/community/Multi-account-1.png">
                    <img src="@/assets/image/community/Multi-account-2.png">
                </li>
                <li>
                    <img src="@/assets/image/community/Multi-account-en-3.png" v-if="$i18n.locale == 'en'">
                    <img src="@/assets/image/community/Multi-account-3.png" v-else>
                </li>
                <li>
                    <img src="@/assets/image/community/Multi-account-en-4.png" v-if="$i18n.locale == 'en'">
                    <img src="@/assets/image/community/Multi-account-4.png" v-else>
                </li>
                <li>
                    <img src="@/assets/image/community/Multi-account-5.png">
                </li>
                <li>
                    <img src="@/assets/image/community/Multi-account-6.png">
                </li>
            </ul>
        </div>
        <div class="tab">
            <div class="tab-box">
                <h4>{{$t('community.multiAccountManagement.describe')}}</h4>
                <div class="tab-describe">
                    <img src="@/assets/image/community/Multi-account-7.png">
                </div>
                <ul>
                    <li>
                        <p>
                            <img src="@/assets/image/community/Multi-account-9.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab1')"></span>
                    </li>
                    <li>
                        <p>
                            <img src="@/assets/image/community/Multi-account-10.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab2')"></span>
                    </li>
                    <li>
                        <p>
                            <img src="@/assets/image/community/Multi-account-11.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab3')"></span>
                    </li>
                    <li>
                        <p>
                            <img src="@/assets/image/community/Multi-account-12.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab4')"></span>
                    </li>
                    <li>
                        <p>
                            <img src="@/assets/image/community/Multi-account-13.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab5')"></span>
                    </li>
                    <li>
                        <p>
                            <img src="@/assets/image/community/following-up-tab-5.png">
                        </p>
                        <span v-html="$t('community.multiAccountManagement.tab6')"></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'view-multi-account-management'
  }
</script>

<style scoped lang="scss">
    .box {
        height: auto;
        overflow: hidden;
        .service{
            position: relative;
            width: 1150px;
            height: 800px;
            padding-bottom: 100px;
            margin: 0 auto;
            .service-describe{
                width: 670px;
                height: 310px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                h4{
                    width: 100%;
                    min-height: 50px;
                    line-height: 50px;
                    font-size: 48px;
                    font-width: 600;
                    color: #000000;
                    margin-top: 82px;
                    white-space: pre;
                }
                p{
                    width: 100%;
                    height: auto;
                    font-size: 16px;
                    font-family: AlibabaPuHuiTiR;
                    color: rgba(0, 0, 0, 0.6);
                    line-height: 24px;
                    margin-top: 14px;
                    padding-right: 20px;
                    word-break:break-all;
                    white-space: pre;
                }
                .btn{
                    margin-top: 26px;
                    border-radius: 20px;
                }
            }
            ul{
                width: 100%;
                height: 100%;
                display: flex;
                li{
                    position: relative;
                    width: 100%;
                    flex: 1;
                    border-right: 2px solid rgba(77,88,98,0.08);
                    &:nth-child(1){
                        border-left: 2px solid rgba(77,88,98,0.08);
                        span{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            display: block;
                            width: 6px;
                            height: 224px;
                            background-color: #0D73D6;
                        }
                        img{
                            display: block;
                            position: absolute;
                            &:nth-child(2) {
                                width: 12px;
                                height: 177px;
                                right: 20px;
                                bottom: 50px;
                            }
                            &:nth-child(3){
                                width: 24px;
                                height: 25px;
                                right: 20px;
                                bottom: 0px;
                            }
                        }
                    }
                    &:nth-child(2){
                        img{
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: calc(100% + 2px);
                            height: 227px;
                        }
                    }
                    &:nth-child(3){
                        img{
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: calc(100% + 2px);
                            height: 318px;
                        }
                    }
                    &:nth-child(4){
                        img{
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: calc(100% + 2px);
                            height: 628px;
                        }
                    }
                    &:nth-child(5){
                        img{
                            display: block;
                            position: absolute;
                            left: 0px;
                            bottom: 0;
                            width: calc(100% + 2px);
                            height: 494px;
                        }
                    }
                }
            }
        }
        .tab{
            width: 100%;
            height: auto;
            overflow: hidden;
            padding: 79px 0 100px;
            background-color: #F5F6F8;
            .tab-box{
                width: 1150px;
                height: auto;
                overflow: hidden;
                margin: 0 auto;
            }
            h4{
                width: 100%;
                height: 65px;
                font-size: 48px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #000000;
                line-height: 65px;
                text-align: center;
            }
            .tab-describe{
                width: 601px;
                height: 310px;
                margin: 40px auto 0px;
            }
            ul{
                width: 100%;
                display: flex;
                li{
                    flex: 1;
                    text-align: center;
                    p{
                        width: 160px;
                        height: 160px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 20px 0px rgba (33,66,171,0.1500);
                        border-radius: 20px;
                        margin: 45px auto 26px;
                        img{
                            width: 56px;
                            height: 56px;
                            margin-top: 52px;
                        }
                    }
                    span{
                        font-size: 16px;
                        font-weight: 400;
                        color:rgba(0, 0, 0, 0.6);
                        line-height: 24px;
                        white-space: pre;
                    }
                }
            }
        }
    }


    @media screen and (min-width: 1200px) {

    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .box{
            .service{
                width: 95vw;

            }
            .tab{
                .tab-box{
                    width: 95vw;
                }
                ul li p{
                    width: 90%;
                    height: 150px;
                    /*height: calc(90% - 44px);*/
                }
            }

        }

    }

    @media screen and (max-width: 992px) {
        .box{
            .service{
                width: 95vw;
                height:450px;
                .service-describe{
                    width: 60%;
                    z-index: 1;
                    h4{
                        min-height: 25px;
                        line-height: 25px;
                        font-size: 24px;
                        margin-top: 22px;
                    }
                    p{
                        font-size: 12px;
                        line-height: 20px;
                        margin-top: 7px;
                        padding-right: 10px;
                        word-break:break-all;
                        white-space: pre;
                    }
                    .btn{
                        margin-top: 16px;
                        border-radius: 10px;
                        height: 20px;
                        line-height: 20px;
                    }
                }
                ul {
                    li{
                        &:nth-child(1){
                            span{
                                width: 3px;
                                height: 112px;
                            }
                            img{
                                &:nth-child(2) {
                                    width: 6px;
                                    height: 88.5px;
                                    right: 10px;
                                    bottom: 25px;
                                }
                                &:nth-child(3){
                                    width: 12px;
                                    height: 12.5px;
                                    right: 10px;
                                }
                            }
                        }
                        &:nth-child(2){
                            img{
                                width: calc(100% + 2px);
                                height: 100px;
                            }
                        }
                        &:nth-child(3){
                            img{
                                width: calc(100% + 2px);
                                height: 119px;
                            }
                        }
                        &:nth-child(4){
                            img{
                                width: calc(100% + 2px);
                                height: 180px;
                            }
                        }
                        &:nth-child(5){
                            img{
                                width: calc(100% + 2px);
                                height: 127px;
                            }
                        }
                    }
                }
            }
            .tab{
                padding: 20px 15px;
                .tab-box{
                    width: 100%;
                    h4{
                        width: 100%;
                        height: 22.5px;
                        line-height: 22.5px;
                        text-align: center;
                        color: #000000;
                        font-size: 20px;
                        font-weight: 600;
                    }
                    .tab-describe{
                        width: 100%;
                        height: 200px;
                        padding-top: 20px;
                        margin: 0;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                ul{
                    flex-flow: wrap;
                    li{
                        flex: none;
                        width: 50%;
                    }
                }
                ul li p{
                    width: 90%;
                    height: 150px;
                    /*height: calc(90% - 44px);*/
                }
            }

        }
    }
</style>