<template>
  <div class="container footer-nav">
    <ul class="nav-list flex flex__justify-center" style="column-gap: 48px;">
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/about-us')">{{$t("header.about")}}</h5>
        <span class="nav-list-item" @click.stop="goTo('/about-us/who')">{{$t("header.aboutUs")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/about-us/contact')">{{$t("header.contactUs")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/about-us/advantage')">{{$t("header.ourAdvantages")}}</span>
<!--        <span class="nav-list-item">{{$t("header.agencyCooperation")}}</span>-->
      </li>
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click.stop="goTo('/platform')">{{$t("header.platform")}}</h5>
        <span class="nav-list-item" @click.stop="goTo('/platform/MT4')">MT4</span>
        <span class="nav-list-item" @click.stop="goTo('/platform/MT5')">MT5</span>
        <span class="nav-list-item">Web Trader</span>
      </li>-->
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/deal')">{{$t("header.product")}}</h5>
        <span class="nav-list-item" @click.stop="goTo('/deal/forex')">{{$t("header.foreignExchange")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/metal')">{{$t("header.nobleMetal")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/energy')">{{$t("header.energy")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/exponent')">{{$t("header.index")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/DigitalCurrency')">{{$t("header.digitalCurrency")}}</span>
<!--        <span class="nav-list-item">{{$t("header.futures")}}</span>-->
      </li>
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/community')">{{$t("header.community")}}</h5>
        <span class="nav-list-item">MAMM</span>
        <span class="nav-list-item" @click.stop="goTo('/community/followingUp')">{{$t("header.followingUp")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/community/multiAccountManagement')">{{$t("header.investor")}}</span>
      </li> -->
      <!-- <li class="nav-list-list flex flex-column"> -->
        <!-- <h5 class="nav-list-item" @click="goTo('/activity')">{{$t("header.activity")}}</h5> -->
<!--        <span class="nav-list-item">{{$t("header.experience")}}</span>-->
<!--        <span class="nav-list-item">{{$t("header.depositRebate")}}</span>-->
<!--        <span class="nav-list-item">Tools</span>-->
      <!-- </li> -->
      <!-- <li class="nav-list-list flex flex-column"> -->
        <!-- <h5 class="nav-list-item" @click="goTo('/notice')">{{$t("header.notice")}}</h5> -->
<!--        <span class="nav-list-item">{{$t("header.recentNotice")}}</span>-->
<!--        <span class="nav-list-item">{{$t("header.transactionSchedule")}}</span>-->
      <!-- </li> -->
<!--      <li class="nav-list-list flex flex-column">-->
<!--        <h5 class="nav-list-item">{{$t("header.notice")}}</h5>-->
<!--        <span class="nav-list-item">Web&nbsp;Trader</span>-->
<!--        <span class="nav-list-item">{{$t("header.energy")}}</span>-->
<!--        <span class="nav-list-item">{{$t("header.investor")}}</span>-->
<!--        <span class="nav-list-item">Tools</span>-->
<!--        <span class="nav-list-item">{{$t("header.signIn")}}</span>-->
<!--      </li>-->
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/support')">{{$t("header.support")}}</h5>
        <span class="nav-list-item" @click.stop="goTo('/support/agent')">{{$t("header.nationalBroker")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/support/fund')">{{$t("header.outOfGoldIntoGold")}}</span>
        <span class="nav-list-item" @click.stop="goTo('/support/faq')">{{$t("header.questionAnswering")}}</span>
        <span class="nav-list-item">{{$t("header.commonProblem")}}</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'footer-nav',
  methods: {
    goTo(path) {
      this.$router.push({ path });

    }
  }
}
</script>

<style scoped lang="scss">
.footer-nav {
  padding-top: 80px;
  padding-top: 82px;
  .nav-list {
    h5 {
      font-size: 16px;
      font-weight: normal;
      color: #fff;

    }
    span {
      margin-top: 24px;
      font-size: 14px;
      color: rgba(255, 255, 255, .8);
    }
    .nav-list-item{
      cursor: pointer;
      &:hover{
        color: var(--button-primary-bg);
      }
    }
  }
}
</style>
