<template>
    <div>
        <div class="index" :class="{'index-Side': SideShow}">
            <div class="head" :class="{'head-Side': SideShow}">
                <div class="head-box">
                    <i @click="SideShow = !SideShow"></i>
                    <div class="logo"></div>
                    <!-- <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t('signIn')}}</button> -->
                </div>
            </div>
            <router-view></router-view>
            <home-footer-phone/>
        </div>
        <div class="Side" :class="{'Side-show' : SideShow}">
            <div class="Side-head">
                <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t('signIn')}}</button>
                <button class="btn btn-primary" @click="$open('login5', $i18n.locale)">{{$t('signIn5')}}</button>
            </div>
            <div class="Side-ul">
                <div class="Side-ul-li" v-for="(item,index) in sideList" :key="index" @click="goTo(item.path)">
                    <img :src="item.imgSrc">
                    {{item.name}}
                    <i v-if="item.children" @click.stop="toOpen(index)" :class="{'drop-down' : index == sideOpenIndex}"></i>
                    <ul v-if="item.children" :class="{'drop-down-ul' : index == sideOpenIndex}">
                        <li v-for="sitem in item.children" @click.stop="goTo(sitem.path)">{{sitem.name}}</li>
                    </ul>
                </div>
            </div>
            <span @click="changeLanguage(langSelectableText)"><b></b> {{langSelectableText}}</span>
        </div>
    </div>
</template>

<script>
    import homeImg from '@/assets/image/home/phone/home.png'
    import weImg from '@/assets/image/home/phone/we.png'
    import platformImg from '@/assets/image/home/phone/platform.png'
    import transactionImg from '@/assets/image/home/phone/transaction.png'
    import communityImg from '@/assets/image/home/phone/community.png'
    import activityImg from '@/assets/image/home/phone/activity.png'
    import noticeImg from '@/assets/image/home/phone/Notice.png'
    import supportImg from '@/assets/image/home/phone/support.png'
    import HomeFooterPhone from "../components/footer/footerPhone";
    export default {
        name: "phone",
        components: {HomeFooterPhone},
      watch: {
        '$route': {
          handler(v) {
                let app = document.querySelector(".index")
                app.scrollTop = 0
              // document.documentElement.scrollTop = document.body.scrollTop = 0;
          },
          deep: true
        }
      },
        data() {
            return {
                homeImg,
                weImg,
                platformImg,
                transactionImg,
                communityImg,
                activityImg,
                noticeImg,
                supportImg,
                SideShow: false,
                sideOpenIndex: 0,
                langText: 'En'
            }
        },
        computed: {
            langSelectableText() {
                return this.langText === 'en' ? '中文' : 'English';
            },
            sideList() {
                let list = [
                    {
                        name: this.$t("header.home"),
                        imgSrc: this.homeImg,
                        path: '/'
                    },{
                        name: this.$t("header.about"),
                        imgSrc: this.weImg,
                        path: '/phone/about-us',
                        children: [
                            {
                                name: this.$t("header.aboutUs"),
                                path: '/phone/about-us/who'
                            }, {
                                name: this.$t("header.contactUs"),
                                path: '/phone/about-us/contact'
                            }, {
                                name: this.$t("header.ourAdvantages"),
                                path: '/phone/about-us/advantage'
                            }
                        ]
                    // },{
                    //     name: this.$t("header.platform"),
                    //     imgSrc: this.platformImg,
                    //     path: '/phone/platform',
                    //     children: [
                    //         {
                    //             name: 'MT4',
                    //             path: '/phone/platform/MT4'
                    //         }, 
                    //         {
                    //             name: 'MT5',
                    //             path: '/phone/platform/MT5'
                    //         }
                    //     ]
                    },{
                        name: this.$t("header.transaction"),
                        imgSrc: this.transactionImg,
                        path: '/phone/deal',
                        children: [
                            {
                                name: this.$t("header.foreignExchange"),
                                path: '/phone/deal/forex'
                            }, {
                                name: this.$t("header.nobleMetal"),
                                path: '/phone/deal/metal'
                            },{
                                name: this.$t("header.energy"),
                                path: '/phone/deal/energy'
                            }, {
                                name: this.$t("header.index"),
                                path: '/phone/deal/exponent'
                            }, {
                                name: this.$t("header.digitalCurrency"),
                                path: '/phone/deal/DigitalCurrency'
                              }
                        ]
                    },{
                        name: this.$t("header.community"),
                        imgSrc: this.communityImg,
                        path: '/phone/community',
                        children: [
                            {
                                name: this.$t("header.followingUp"),
                                path: '/phone/community/followingUp'
                            }, {
                                name: this.$t("header.multiAccountManagement"),
                                path: '/phone/community/multiAccountManagement'
                            }
                        ]
                    },{
                        name: this.$t("header.activity"),
                        imgSrc: this.activityImg,
                        path: '/phone/activity'
                    },{
                        name: this.$t("header.notice"),
                        imgSrc: this.noticeImg,
                        path: '/phone/notice'
                    },{
                        name: this.$t("header.support"),
                        imgSrc: this.supportImg,
                        path: '/phone/support',
                        children: [
                            {
                                name: this.$t("header.nationalBroker"),
                                path: '/phone/support/agent'
                            }, {
                                name: this.$t("header.outOfGoldIntoGold"),
                                path: '/phone/support/fund'
                            }, {
                                name: this.$t("header.questionAnswering"),
                                path: '/phone/support/faq'
                            }
                        ]
                    }
                ]
                return list
            }
        },
        methods: {
            goTo(path) {
                this.SideShow = false;
                this.$router.push({ path });
            },
            toOpen(index) {
                this.sideOpenIndex == index ? this.sideOpenIndex = 0 : this.sideOpenIndex = index
            },
            changeLanguage(lang) {
                this.langText = (lang == '中文' ? 'zh' : 'en')
                this.$i18n.locale = (lang === '中文' ? 'zh' : 'en');
                this.SideShow = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .index{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        transition: all 0.2s;
    }
    .head{
        width: 100%;
        height: 44px;
        line-height: 44px;
        position: fixed;
        background-color: #1A1A1A;
        top: 0;
        left: 0;
        z-index: 999;
        transition: all 0.2s;
        .head-box{
            position: relative;
            width: 100%;
            height: 100%;
            i{
                position: absolute;
                left: 16px;
                top: 8px;
                display: block;
                width: 28px;
                height: 28px;
                @include bg-image('../assets/image/home/phone/menu');
                background-size: cover;
            }
            .logo{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                width: 50px;
                height: 18px;
                // @include bg-image('../assets/image/home/phone/ft');
                background-size: cover;
                z-index: 1;
            }
            .btn{
                position: absolute;
                top: 10px;
                right: 10px;
                min-width: 60px;
                height: 24px;
                line-height: 24px;
            }
        }

    }
    .index-Side{
        left: 285px;
        transition: all 0.2s;
        .head-Side{
            left: 285px;
            transition: all 0.2s;
        }
    }
    .Side{
        position: fixed;
        left: 0;
        top: 0;
        width: 0;
        height: 100vh;
        z-index: 999;
        background-color: #1A1A1A;
        overflow: hidden;
        transition: all 0.2s;
        padding-top: 30px;
        .Side-head{
            width: 100%;
            height: 60px;
            padding-bottom: 33px;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            text-align: center;
            .btn{
                min-width: 80px;
                height: 24px;
                line-height: 24px;
                margin-bottom: 8px;
            }
        }
        .Side-ul{
            width: 100%;
            height: calc(100vh - 220px);
            overflow-y: auto;
            margin-top: 28px;
            padding-left: 17.5px;
            .Side-ul-li{
                width: 100%;
                min-height: 23px;
                line-height: 23px;
                color: #fff;
                font-size: 16px;
                margin-bottom: 32px;
                position: relative;
                img{
                    float: left;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    margin-top: 2.5px;
                    image-rendering: -webkit-optimize-contrast;
                }
                i{
                    position: absolute;
                    right: 10px;
                    top: 3px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: url("../assets/image/home/phone/drop-down.png");
                    background-size: cover;
                    background-position: center center;
                    transition: all 0.2s;
                }
                .drop-down{
                    transform: rotate(180deg);
                    transition: all 0.2s;
                }
                ul{
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    padding-left: 28px;
                    transition: all 0.2s;
                    li{
                        width: 100%;
                        height: 22.5px;
                        line-height: 22.5px;
                        font-size: 16px;
                        margin-top: 30px;
                        color: rgba(255,255,255,0.8);
                    }
                }
                .drop-down-ul{
                    height: auto;
                    overflow: hidden;
                    transition: all 0.2s;
                }
            }
        }
        span{
            width: 100%;
            float: right;
            text-align: right;
            font-size: 16px;
            b{
                width: 18px;
                height: 18px;
                /*float: left;*/
                display: inline-block;
                background: url("../assets/image/home/phone/conversion.png");
                background-size: cover;
            }
        }
    }
    .Side-show{
        width: 285px;
        height: 100vh;
        left: 0;
        top: 0;
        /*transition: all 0.2s;*/
        padding: 30px 22.5px;

    }
</style>