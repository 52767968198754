import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'

import App from './App.vue'
import router from './router'
import { zh, en } from './assets/locales/index'

import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/index.css'

import md5 from 'js-md5'
Vue.prototype.$md5 = md5

Vue.config.productionTip = false

const skipUrl = {
  login: 'https://ib.ft66fx.com/?language=',
  login5: 'https://ibt5.ft66fx.com/?language=',
  signUp: 'https://ib.ft66fx.com/register?share_id=102&type=register&ssid=9&time=1652698694792&sign=21f31d1be9d1aa361e79c577f4cc8fb3&language=',
  signUp5: 'https://ibt5.ft66fx.com/share_register/c2hhcmVfaWQ9ODk1JnR5cGU9cmVnaXN0ZXImc3NpZD0zOSZ0aW1lPTE2NzAwOTAwMjI3OTUmc2lnbj1iYWU3YWY3YjU2M2YyZGI2ZmYzMzg3NDdlODM1ZjM1NA==?language=',
  android: 'https://ib.ft66fx.com/?language=', // 'https://www.ft66fx.com/soft/android_MT4.apk',
  iOS: 'https://ib.ft66fx.com/?language=', // 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=FutaiInternational-Demo,FutaiInternational-Live',
  pc: 'https://ib.ft66fx.com/?language=', // 'https://www.ft66fx.com/soft/futaiinternational4setup.exe',
  mac: 'https://ib.ft66fx.com/?language=', // 'https://www.ft66fx.com/soft/MetaTrader4.dmg',
  partake: 'https://ib.ft66fx.com/?language=', // 'https://ib.ft66fx.com/register?share_id=102&type=register&ssid=9&time=1652698694792&sign=21f31d1be9d1aa361e79c577f4cc8fb3&language=',
  activity: 'https://www.ft66fx.com/#/activity'
}
Vue.use(Element, { size: 'mini' })
Vue.use(VueI18n)
Vue.prototype.$open = function(key, lang) {
  return
  const openUrl = ['login', 'login5', 'signUp', 'signUp5'].indexOf(key) > -1 ? `${skipUrl[key]}${lang === 'en' ? 'en_US' : 'zh_CN'}` : skipUrl[key]
  window && window.open(openUrl)
}

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    zh: { ...zh },
    en: { ...en },
  },
  // silentTranslationWarn: true,
})

window.vue = new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app')
