import { render, staticRenderFns } from "./start-trading-slider-phone.vue?vue&type=template&id=25df61c6&scoped=true"
import script from "./start-trading-slider-phone.vue?vue&type=script&lang=js"
export * from "./start-trading-slider-phone.vue?vue&type=script&lang=js"
import style0 from "./start-trading-slider-phone.vue?vue&type=style&index=0&id=25df61c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25df61c6",
  null
  
)

export default component.exports